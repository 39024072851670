<template>
  <div class="fitment-model">
    <van-nav-bar
      title="装修模块"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
    />
    <!-- tab栏 -->
    <van-tabs
      v-model="active"
      color="#FF5200"
      line-width="10"
      title-active-color="#FF5200"
      @change="activeChange"
    >
      <!-- 商品 -->
      <van-tab title="商品">
        <div class="tab-inner">
          <div class="half-box">
            <commodity-two @choose="choose([1, 1])"></commodity-two>
            <commodity-big-pic @choose="choose([1, 3])"></commodity-big-pic>
          </div>
          <div class="half-box">
            <commodity-list @choose="choose([1, 2])"></commodity-list>
          </div>
        </div>
      </van-tab>
      <!-- 导航 -->
      <van-tab title="导航">
        <div class="tab-inner">
          <div class="half-box">
            <navigation-pic @choose="choose([2, 1])"></navigation-pic>
          </div>
          <div class="half-box">
            <navigation-slide @choose="choose([2, 2])"></navigation-slide>
          </div>
        </div>
      </van-tab>
      <!-- 广告 -->
      <van-tab title="广告">
        <div class="tab-inner">
          <div class="half-box">
            <ad-big-pic @choose="choose([3, 1])"></ad-big-pic>
            <ad-little-slide @choose="choose([3, 4])"></ad-little-slide>
          </div>
          <div class="half-box">
            <ad-two @choose="choose([3, 2])"></ad-two>
            <ad-swiper @choose="choose([3, 3])"></ad-swiper>
            <ad-big-slide @choose="choose([3, 5])"></ad-big-slide>
          </div>
        </div>
      </van-tab>
      <!-- 文字 -->
      <van-tab title="文字">
        <div class="tab-inner">
          <div class="half-box">
            <text-scroll
              v-if="textScrollShow"
              @choose="choose([4, 1])"
            ></text-scroll>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import commodityBigPic from "@/components/fitment-box/commodity-big-pic";
import commodityList from "@/components/fitment-box/commodity-list";
import commodityTwo from "@/components/fitment-box/commodity-two";
import navigationPic from "@/components/fitment-box/navigation-pic";
import navigationSlide from "@/components/fitment-box/navigation-slide";
import adBigPic from "@/components/fitment-box/ad-big-pic";
import adTwo from "@/components/fitment-box/ad-two";
import adLittleSlide from "@/components/fitment-box/ad-little-slide";
import adBigSlide from "@/components/fitment-box/ad-big-slide";
import adSwiper from "@/components/fitment-box/ad-swiper";
import textScroll from "@/components/fitment-box/text-scroll";
export default {
  name: "fitmentModel",
  components: {
    commodityTwo,
    commodityBigPic,
    commodityList,
    navigationPic,
    navigationSlide,
    adBigPic,
    adTwo,
    adLittleSlide,
    adBigSlide,
    adSwiper,
    textScroll,
  },
  data() {
    return {
      site: null,
      active: 0,
      textScrollShow: true,
    };
  },
  created() {
    this.site = +this.$route.query.site;
  },
  methods: {
    activeChange(name) {
      this.textScrollShow = name == 3 ? true : false;
    },
    // 选择
    choose(item) {
      this.$store.commit("listSplice", {
        index: this.site,
        item,
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-model {
  background-color: #f6f6f6;
  .tab-inner {
    display: flex;
    .half-box {
      width: 50%;
    }
  }
}
</style>